import { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EventContext } from '../../Contexts/Events';
import Busy from '../../Components/Busy';
import Modal from '../../Components/Modal';
import { ReactComponent as IconArrow } from '../../assets/images/arrow.svg';
import moment from 'moment';


function NotificationView() {
    const navigate = useNavigate();
    const loading = useRef(true);
    const eventHandler = useContext(EventContext);
    const { notificationId } = useParams();

    const [notificationState, setNotificationState] = useState({
        busy: true,
        notification: false as any,
        error: false as any
    });

    useEffect(() => {
        if (loading.current) {
            loading.current = false;
            eventHandler.trigger('notification:request', notificationId);
        }
    }, [navigate, eventHandler, notificationId]);

    useEffect(() => {
        const receiveNotification = (notification: any) => {
            if (!notification) {
                navigate('/notifications', {
                    state: {
                        error: {
                            title: 'Error',
                            message: 'The requested notification could not be loaded.'
                        }
                    }
                });
                return;
            }
            setNotificationState((notificationState: any) => ({ ...notificationState, busy: false, notification }));
            if (!notification.read_at) {
                eventHandler.trigger('notification:update-local', notificationId, {
                    read_at: moment().format('YYYY-MM-DD HH:mm:ss')
                }, true);
            }
        }
        eventHandler.on('notification:receive:' + notificationId, receiveNotification);
        return () => {
            eventHandler.off('notification:receive:' + notificationId, receiveNotification);
        }
    }, [eventHandler, notificationId, navigate]);

    return (
        <div className={`NotificationView ${(notificationState.busy ? 'is--busy' : '')}`}>
            {notificationState.error !== false && (
                <Modal onDismiss={() => setNotificationState({ ...notificationState, error: false })} title={notificationState.error.title}>{notificationState.error.message}</Modal>
            )}
            {notificationState.busy && (
                <Busy />
            )}
            {!notificationState.busy && (
                <>

                    <Link className="Back" to="/notifications">
                        <span>
                            <IconArrow width={15.91} height={24.6} />
                        </span>
                        Back to Notifications
                    </Link>
                    <h1>{notificationState.notification.data.title}</h1>
                    <small>{moment(notificationState.notification.created_at).format('DD/MM/YYYY HH:mm')}</small>
                    {notificationState.notification.data.body.split('\n').map((item: any, key: any) => {
                        return <span key={key}>{item}<br/></span>
                    })}
                </>
            )}
        </div>
    );
}

export default NotificationView;