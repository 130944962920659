import Login from '../Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.scss';
import Private from '../Layout/Private';
import Public from '../Layout/Public';
import NotFound from '../NotFound';
import ForgottenPassword from '../ForgottenPassword';
import ShiftList from '../Shifts/List';
import ShiftView from '../Shifts/View';
import Onboarding from '../Onboarding';
import Holidays from '../Holidays';
import Resources from '../Resources';
import Profile from '../Profile';
import NotificationList from '../Notifications/List';
import NotificationView from '../Notifications/View';
import { useServiceWorker } from '../useServiceWorker';
import { useContext, useEffect, useState } from 'react';
import Modal from '../Components/Modal';
import { EventContext } from '../Contexts/Events';
import Setup from '../Setup';
import Debug from '../Debug';
import Update from '../Update';
import Install from '../Install';
import Installed from '../Installed';

function App() {

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [updateState, setUpdateState] = useState(false);
  const eventHandler = useContext(EventContext);

  useEffect(() => {
    if (showReload && waitingWorker) {
      // setUpdateState(true);
      
    }
    const interval = setInterval(() => {
      eventHandler.trigger('notifications:request');
    }, 5000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [waitingWorker, showReload, eventHandler]);

  return (
    <BrowserRouter>
      {updateState && (
        <Modal onDismiss={() => setUpdateState(false)} title="Update Available">
          <p>An update is available for the app.</p>
          <button className="PrimaryButton" onClick={() => reloadPage()}>Update</button>
        </Modal>
      )}
      <Routes>
        <Route path="/" element={<Private />}>
          <Route index element={<ShiftList />} />
          <Route path="shifts/view/:shiftId" element={<ShiftView />} />
          <Route path="holidays" element={<Holidays />} />
          <Route path="setup" element={<Setup />} />
          <Route path="onboarding" element={<Onboarding />} />
          <Route path="resources" element={<Resources />} />
          <Route path="profile" element={<Profile />} />
          <Route path="notifications" element={<NotificationList />} />
          <Route path="notifications/view/:notificationId" element={<NotificationView />} />
          <Route path="debug" element={<Debug />} />
          <Route path="update" element={<Update />} />
        </Route>
        <Route path="/" element={<Public />}>
          <Route path="install" element={<Install />} />
          <Route path="installed" element={<Installed />} />
          <Route path="login" element={<Login />} />
          <Route path="forgotten-password" element={<ForgottenPassword />} />
          <Route path="update" element={<Update />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
