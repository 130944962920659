import { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { EventContext } from '../Contexts/Events';
import { ReactComponent as IconArrow } from '../assets/images/arrow.svg';
import moment from 'moment';
import Busy from '../Components/Busy';
import Modal from '../Components/Modal';

function Holidays() {
    const eventHandler = useContext(EventContext);
    const loading = useRef(true);

    const statusMap: any = {
        pending: 'Pending Holiday Requests',
        approved: 'Approved Holiday Requests',
        rejected: 'Rejected Holiday Requests',
        past: 'Previous Holidays',
    }

    const [holidayState, setHolidayState] = useState({
        busy: true,
        updating: false,
        holidays: {} as any,
        remaining: 0,
        error: false as any,
        online: navigator.onLine,
        units: -1,
        starts_at: '',
        starts_at_time: '09:00',
        ends_at: '',
        ends_at_time: '17:00',
        visibleHoliday: false as any
    });

    const formatHolidays = (holidays: any) => {
        let output: any = {},
            today: any = moment(),
            key: any;

        holidays.forEach((holiday: any) => {
            const starts_at = moment('YYYY-MM-DD HH:mm', holiday.starts_at);
            key = holiday.status;
            if (starts_at.isBefore(today)) {
                key = 'past';
            }
            if (key !== 'past' || holiday.status === 'approved') {
                if (!(key in output)) {
                    output[key] = [];
                }
                output[key].push(holiday);
            }
        });
        return output;
    }

    const submit = (e: any) => {
        e.preventDefault();

        setHolidayState({...holidayState, updating: true});

        eventHandler.trigger(
            'holidays:submit', 
            holidayState.starts_at + ' ' + holidayState.starts_at_time,
            holidayState.ends_at + ' ' + holidayState.ends_at_time,
        );
    }

    useEffect(() => {
        const receiveHolidays = (holidays: any) => {
            setHolidayState({
                ...holidayState, 
                busy: false, 
                updating: false, 
                starts_at: '',
                starts_at_time: '09:00',
                ends_at: '',
                ends_at_time: '17:00',
                units: -1,
                holidays: formatHolidays(holidays)
            });
            const el = document.querySelector('#RequestHoliday');
            if (el) {
                el.classList.remove('active');
            }
        }
        const updateRemaining = (remaining: any) => {
            setHolidayState({...holidayState, remaining});
        }
        const networkChange = (online: any) => {
            setHolidayState({...holidayState, online});
        }
        if (loading.current) {
            eventHandler.trigger('holidays:request');
            eventHandler.trigger('profile:request');
            loading.current = false;
        }

        eventHandler.on('network:change', networkChange);
        eventHandler.on('holidays:receive', receiveHolidays);
        eventHandler.on('holidays:update-remaining', updateRemaining);
        return () => {
            eventHandler.off('network:change', networkChange);
            eventHandler.off('holidays:receive', receiveHolidays);
            eventHandler.off('holidays:update-remaining', updateRemaining);
        }
    }, [eventHandler, holidayState]);

    const toggleGroup = (e: any) => {
        let header = (e.target.tagName !== 'header' ? e.target.closest('header') : e.target);
        header.parentNode.classList.toggle('active');
    }

    const dateChanged = (e: any) => {
        let newState: any = {...holidayState, [e.target.name]: e.target.value};

        if (newState.starts_at && newState.ends_at) {
            let starts_at = moment(newState.starts_at + ' ' + newState.starts_at_time, 'YYYY-MM-DD HH:mm'),
                ends_at = moment(newState.ends_at + ' ' + newState.ends_at_time, 'YYYY-MM-DD HH:mm'),
                units = ends_at.diff(starts_at, 'days') || 1;

            if (newState.starts_at_time !== '00:00' || newState.ends_at_time !== '00:00') {
                units -= 0.5;
            }

            if (ends_at.isBefore(starts_at)) {
                units = -1;
                newState[e.target.name] = '';
                newState.error = {
                    title: 'Holiday Request',
                    message: 'The end date must be after the start date'
                };
            }

            if (units > Number.parseFloat(newState.remaining)) {
                newState.error = {
                    title: 'Holiday Request',
                    message: 'You have requested more days than you have available.<br /><br />Your request may be rejected.'
                };
            }

            newState.units = units;
        }

        setHolidayState(newState);
    }

    const showHoliday = (holiday: any) => {
        if (holiday.status !== 'pending') {
            setHolidayState({...holidayState, visibleHoliday: holiday});
        }
    }

    return (
        <div className={`Holidays ${holidayState.busy ? 'is--busy' : ''}`}>
            {holidayState.error !== false && (
                <Modal onDismiss={() => setHolidayState({ ...holidayState, error: false })} title={holidayState.error.title}>{holidayState.error.message}</Modal>
            )}
            {holidayState.busy && (
                <Busy />
            )}
            {!holidayState.busy && (
                <>
                    {holidayState.remaining > -1 && (
                        <p className="RemainingHolidayEntitlement">
                            <strong>Remaining Holiday Entitlement:</strong> {holidayState.remaining.toString().replace('.00', '')} day{(holidayState.remaining === 1 ? '' : 's')}
                        </p>
                    )}
                    <div className="HolidayGroup" id="RequestHoliday">
                        <header onClick={toggleGroup}>
                            <strong>Request Holidays</strong>
                            <span><IconArrow width={15.91} height={24.6} /></span>
                        </header>
                        <section>
                            {!holidayState.online && (
                                <div className="Offline">Please connect to the internet to request a holiday</div>
                            )}
                            {holidayState.online && (
                                <form onSubmit={submit} method="post">
                                    <div>
                                        <input type="date" min={moment().format('YYYY-MM-DD')} name="starts_at" required pattern="\d{4}-\d{2}-\d{2}" value={holidayState.starts_at || ''} onChange={dateChanged} placeholder="dd/mm/yyyy" />
                                        <input type="time" name="starts_at_time" required value={holidayState.starts_at_time || ''} onChange={dateChanged} placeholder="hh:mm" />
                                    </div>
                                    <div>
                                        <input type="date" min={moment().format('YYYY-MM-DD')} name="ends_at" required pattern="\d{4}-\d{2}-\d{2}" value={holidayState.ends_at || ''} onChange={dateChanged} placeholder="dd/mm/yyyy" />
                                        <input type="time" name="ends_at_time" required value={holidayState.ends_at_time || ''} onChange={dateChanged} placeholder="hh:mm" />
                                    </div>
                                    {false && holidayState.units > 0 && (
                                        <p>You are requesting <strong>{holidayState.units} day{holidayState.units === 1 ? '' : 's'}</strong> holiday to be taken from your holiday entitlement.</p>
                                    )}
                                    <button disabled={holidayState.units <= 0}>
                                        {holidayState.updating ? <Busy /> : 'Request Holiday'}
                                    </button>
                                </form>
                            )}
                        </section>
                    </div>
                    {Object.keys(holidayState.holidays).map((status: any) => {
                        return (
                            <div className="HolidayGroup" key={status}>
                                <header onClick={toggleGroup}>
                                    <strong>{statusMap[status]}</strong>
                                    <span><IconArrow width={15.91} height={24.6} /></span>
                                </header>
                                <section>
                                    <ul>
                                        {holidayState.holidays[status].map((holiday: any) => {
                                            // const units_used = Number.parseFloat(holiday.units_used);
                                            const starts_at = moment(holiday.starts_at, 'YYYY-MM-DD HH:mm').format('DD/MM/YY HH:mm');
                                            const ends_at = moment(holiday.ends_at, 'YYYY-MM-DD HH:mm').format('DD/MM/YY HH:mm');
                                            return (
                                                <li key={holiday.id} onClick={() => showHoliday(holiday)}>
                                                    <small className={status}>{status.substring(0,1).toUpperCase() + status.substring(1).toLowerCase()}</small>
                                                    {/* <p><strong>{units_used.toString().replace('.00', '')} day{units_used === 1 ? '' : 's'}</strong> {[starts_at, ends_at].filter((v, i, a) => a.indexOf(v) === i).join(' - ')}</p> */}
                                                    <p>{[starts_at, ends_at].filter((v, i, a) => a.indexOf(v) === i).join(' - ')}</p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </section>
                            </div>
                        )
                    })}
                </>
            )}
            {holidayState.visibleHoliday && (
                <>
                    {[0].map(() => {
                        const holiday = holidayState.visibleHoliday;
                        // const units_used = Number.parseFloat(holiday.units_used);
                        const starts_at = moment(holiday.starts_at, 'YYYY-MM-DD HH:mm').format('DD/MM/YY HH:mm');
                        const ends_at = moment(holiday.ends_at, 'YYYY-MM-DD HH:mm').format('DD/MM/YY HH:mm');
                        return (
                            <Modal onDismiss={() => setHolidayState({...holidayState, visibleHoliday: false})} title={[starts_at, ends_at].filter((v, i, a) => a.indexOf(v) === i).join(' - ')}>
                                <div className="HolidayModal">
                                    <dl>
                                        {/* <dt>Duration:</dt>
                                        <dd>{units_used.toString().replace('.00', '')} day{units_used === 1 ? '' : 's'}</dd> */}
                                        <dt>Status:</dt>
                                        <dd><small className={holiday.status}>{holiday.status.substring(0,1).toUpperCase() + holiday.status.substring(1).toLowerCase()}</small></dd>
                                        {holiday.status === 'approved' && !!holiday.auth_code && (
                                            <>
                                                <dt>Auth Code:</dt>
                                                <dd>{holiday.auth_code}</dd>
                                            </>
                                        )}
                                    </dl>
                                    {holiday.status !== 'pending' && !!holiday.status_comments && (
                                        <p><strong>Comments:</strong><br />{holiday.status_comments}</p>
                                    )}
                                </div>
                            </Modal>
                        );
                    })}
                </>
            )}
        </div>
    )
}

export default Holidays;