import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../../Components/Busy';
import { ReactComponent as IconArrow } from '../../assets/images/arrow.svg';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EventContext } from '../../Contexts/Events';
import Modal from '../../Components/Modal';
import { updatedDiff } from 'deep-object-diff';
import * as Sentry from "@sentry/browser";

function ShiftView() {
    const [shiftState, setShiftState] = useState({
        busy: true,
        updating: false,
        buttonActive: false,
        shift: false as any,
        locationReported: false,
        zones: [],
        location: false as any,
        locationError: false,
        locationHelpVisible: false
    });

    const loading = useRef(true);
    const { shiftId } = useParams();
    const navigate = useNavigate();
    const eventHandler = useContext(EventContext);
    let positionWatcherInterval: any = useRef(false);

    const isWithinRadius = (checkPoint: any, centerPoint: any) => {
        let ky = 40000 / 360,
            kx = Math.cos(Math.PI * centerPoint.latitude / 180.0) * ky,
            dx = Math.abs(centerPoint.longitude - checkPoint.lng) * kx,
            dy = Math.abs(centerPoint.latitude - checkPoint.lat) * ky,
            t = Math.sqrt(dx * dx + dy * dy);
        return t <= checkPoint.radius;
    }

    const isWithinTimeWindow = (window: any, date: any) => {
        if (window === 0) return true;
        const minutes = date.diff(moment(), 'minutes');
        return minutes <= window;
    }

    const parseZone = (zone: any) => {
        if (!zone) {
            return false;
        }

        const [lat, lng, zoom, radius] = zone.split(',');
        return {
            lat,
            lng,
            zoom,
            radius
        };
    }

    const positionUpdated = useCallback((pos: any) => {
        let active: any = false,
            starts_at: any = moment(shiftState.shift.starts_at, 'YYYY-MM-DD HH:mm'),
            ends_at: any = moment(shiftState.shift.ends_at, 'YYYY-MM-DD HH:mm');

        if (!shiftState.updating) {
            if (shiftState.shift && !shiftState.shift.attendance.signin_at) {
                shiftState.zones.forEach((zone: any) => {
                    if ((!zone.sign_in_window || isWithinTimeWindow(zone.sign_in_window, starts_at)) && isWithinRadius(zone.sign_in_zone, pos.coords)) {
                        active = true;
                        return true;
                    }
                });
            }

            if (shiftState.shift && shiftState.shift.attendance.signin_at && !shiftState.shift.attendance.signout_at) {
                shiftState.zones.forEach((zone: any) => {
                    if ((!zone.sign_out_window || isWithinTimeWindow(zone.sign_out_window, ends_at)) && isWithinRadius(zone.sign_out_zone, pos.coords)) {
                        active = true;
                        return true;
                    }
                });
            }
        }
        if (document.hidden) {
            active = false;
        }
        if (!shiftState.location || shiftState.location.timestamp !== pos.timestamp || active !== shiftState.buttonActive) {
            let locationHelpVisible = shiftState.locationHelpVisible;
            if (locationHelpVisible && active) {
                locationHelpVisible = false;
            }
            setShiftState(state => ({ ...state, locationError: false, buttonActive: active, location: pos, locationHelpVisible }));
        }
    }, [shiftState]);

    const shiftAction = () => {
        setShiftState((state) => ({ ...state, updating: true, buttonActive: false }));
        let attendance = { ...shiftState.shift.attendance };
        if (!attendance.signin_at) {
            Sentry.addBreadcrumb({
                category: 'shift',
                message: 'Sign in - ' + attendance.id,
                level: 'info',
            });

            attendance.status = 'signed_in';
            attendance.signin_at = moment().format('YYYY-MM-DD HH:mm:ss');
            attendance.signin_location = (shiftState.location ? shiftState.location.coords.latitude + ',' + shiftState.location.coords.longitude : attendance.signin_location);
        } else if (attendance.signin_at && !attendance.signout_at) {
            Sentry.addBreadcrumb({
                category: 'shift',
                message: 'Sign out - ' + attendance.id,
                level: 'info',
            });

            attendance.status = 'signed_out';
            attendance.signout_at = moment().format('YYYY-MM-DD HH:mm:ss');
            attendance.signout_location = (shiftState.location ? shiftState.location.coords.latitude + ',' + shiftState.location.coords.longitude : attendance.signout_location);
        }
        let diff: any = updatedDiff(shiftState.shift.attendance, attendance);
        eventHandler.trigger('shift:update-local', shiftId, Object.keys(diff).reduce((acc: any, key: any) => {
            acc['attendance.' + key] = diff[key];
            return acc;
        }, {}), true);
    }

    const logLocation = () => {
        Sentry.addBreadcrumb({
            category: 'shift',
            message: 'Location Issue Reported',
            level: 'info',
        });
        setShiftState((state) => ({ ...state, updating: true }));
        eventHandler.trigger('location:log', shiftId, shiftState.shift.attendance.id, (shiftState.location ? shiftState.location.coords.latitude + ',' + shiftState.location.coords.longitude : false));
        navigate('/', {
            state: {
                error: {
                    title: 'Location Issue Reported',
                    message: 'Thank you for reporting an issue with your location.'
                }
            }
        });
    }

    useEffect(() => {
        const shiftReceived = (shift: any = undefined) => {
            if (!shift) {
                navigate('/', {
                    state: {
                        error: {
                            title: 'Error',
                            message: 'The requested shift could not be loaded.'
                        }
                    }
                });
                return;
            }
            Sentry.addBreadcrumb({
                category: 'shift',
                message: 'Received: ' + shift.id,
                level: 'info',
            });
            let zones: any = shift.locations.reduce((acc: any, item: any) => {
                if (item.site.sign_in_zone || item.site.sign_out_zone) {
                    acc.push({
                        sign_in_window: (item.site.sign_in_window === -2 ? acc[0].sign_in_window : (item.site.sign_in_window > 0 ? item.site.sign_in_window : 0)),
                        sign_out_window: (item.site.sign_out_window === -2 ? acc[0].sign_out_window : (item.site.sign_out_window > 0 ? item.site.sign_out_window : 0)),
                        sign_in_zone: parseZone(item.site.sign_in_zone) || acc[0].sign_in_zone,
                        sign_out_zone: parseZone(item.site.sign_out_zone) || acc[0].sign_out_zone,
                    });
                }
                return acc;
            }, [{
                sign_in_window: (shift.client.sign_in_window <= 0 ? 0 : shift.client.sign_in_window),
                sign_out_window: (shift.client.sign_out_window <= 0 ? 0 : shift.client.sign_out_window),
                sign_in_zone: parseZone(shift.client.sign_in_zone),
                sign_out_zone: parseZone(shift.client.sign_out_zone),
            }]);
            setShiftState(state => ({ ...state, updating: false, busy: false, shift: shift, zones }));
            if (shiftState.location) {
                positionUpdated(shiftState.location);
            }
        }

        const visibilityChanged = () => {
            setShiftState(state => ({ ...state, location: false, buttonActive: false }));
        }

        if (loading.current) {
            Sentry.addBreadcrumb({
                category: 'shift',
                message: 'Requested: ' + shiftId,
                level: 'info',
            });
            eventHandler.trigger('shift:request', shiftId);
            loading.current = false;
        }

        eventHandler.on('shift:receive:' + shiftId, shiftReceived);
        window.addEventListener('visibilitychange', visibilityChanged);
        window.addEventListener('focus', visibilityChanged);
        window.addEventListener('blur', visibilityChanged);
        return () => {
            eventHandler.off('shift:receive:' + shiftId, shiftReceived);
            window.removeEventListener('visibilitychange', visibilityChanged);
            window.removeEventListener('focus', visibilityChanged);
            window.removeEventListener('blur', visibilityChanged);
        }
    }, [shiftState, shiftId, positionUpdated, navigate, eventHandler]);

    useEffect(() => {
        if (navigator.geolocation) {
            clearInterval(positionWatcherInterval.current);
            positionWatcherInterval.current = setInterval(() => {
                setShiftState(state => ({...state, location: false }));
                navigator.geolocation.getCurrentPosition(positionUpdated, () => {
                    setShiftState(state => ({ ...state, locationError: true, updating: false, buttonActive: false, location: false }));
                }, {
                    maximumAge: 5000
                });
            }, 5000);
        } else {
            Sentry.addBreadcrumb({
                category: 'shift',
                message: 'Location not available!',
                level: 'error',
            });
            setShiftState(state => ({ ...state, locationError: true, updating: false, buttonActive: false, location: false }));
        }

        return () => {
            if (positionWatcherInterval.current) {
                clearInterval(positionWatcherInterval.current);
                positionWatcherInterval.current = false;
            }
        }
    }, [positionUpdated, shiftState.shift])

    return (
        <div className={`ShiftView ${shiftState.busy ? 'is--busy' : ''}`}>
            {shiftState.busy && (
                <Busy />
            )}
            {!shiftState.busy && (
                <>
                    <Link className="Back" to="/">
                        <span>
                            <IconArrow width={15.91} height={24.6} />
                        </span>
                        Back to Shifts
                    </Link>
                    {shiftState.locationError && (
                        <div className="LocationError">We cannot determine your current location.</div>
                    )}
                    {!shiftState.buttonActive && shiftState.locationHelpVisible && (
                        <Modal title={`Why can't I sign ${(shiftState.shift.attendance.status === 'pending' ? 'in' : 'out')}?`} onDismiss={() => setShiftState({ ...shiftState, locationReported: false, locationHelpVisible: false })}>
                            <div>
                                <p>The sign in/out button will only activate when you are at the required location at the scheduled time.</p>
                                {shiftState.location && (
                                    <button type="button" className="LocationIssue" onClick={() => logLocation()} disabled={shiftState.updating}>
                                        Report Location Issue
                                    </button>
                                )}
                            </div>
                        </Modal>
                    )}
                    {shiftState.shift.attendance.status === 'signed_out' && (
                        <p className="ShiftCompleted">This shift has been completed.</p>
                    )}
                    <div className="ShiftViewDetail">
                        <h2>{moment(shiftState.shift.starts_at, 'YYYY-MM-DD HH:mm').format('dddd Do MMMM, h:mma')} - {moment(shiftState.shift.ends_at, 'YYYY-MM-DD HH:mm').format('h:mma')}</h2>
                        <h1>{shiftState.shift.client.name}</h1>
                        {shiftState.shift.locations.map((location: any, idx: any) => {
                            return (
                                <p className="ShiftLocation" key={idx}><span>{location.site.name}</span> <span>{location.building ? location.building.name : 'All Buildings'}</span></p>
                            );
                        })}
                        <p>{shiftState.shift.client.address_line_1}{shiftState.shift.client.address_line_2.length ? ', ' + shiftState.shift.client.address_line_2 : ''}, {shiftState.shift.client.town}, {shiftState.shift.client.county}<br />{shiftState.shift.client.postcode}</p>
                        {['pending', 'signed_in', 'late'].indexOf(shiftState.shift.attendance.status) > -1 && (
                            <>
                                <button type="button" className="ShiftAction" onClick={() => shiftAction()} disabled={!shiftState.location || !shiftState.buttonActive || shiftState.updating}>
                                    {!shiftState.locationError && (shiftState.updating || shiftState.location === false) ? <Busy /> : (shiftState.locationError ? 'Location Error' : `Sign ${shiftState.shift.attendance.signin_at ? 'Out' : 'In'}`)}
                                </button>
                                {!shiftState.buttonActive && (
                                    <button type="button" className="ShiftLink" onClick={() => setShiftState({ ...shiftState, locationHelpVisible: true })}>
                                        Why can&#39;t I sign {(shiftState.shift.attendance.signin_at ? 'out' : 'in')}?
                                    </button>
                                )}
                            </>
                        )}
                        {shiftState.shift.description && shiftState.shift.description.length > 0 && (
                            <>
                                <p><strong>Description:</strong></p>
                                <div dangerouslySetInnerHTML={{ __html: shiftState.shift.description }}></div>
                            </>
                        )}
                    </div>
                </>
            )}

        </div>
    );
}

export default ShiftView;
