import React, { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../../Components/Busy';
import { ReactComponent as IconArrow } from '../../assets/images/arrow.svg';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../Components/Modal';
import { EventContext } from '../../Contexts/Events';
import * as Sentry from "@sentry/browser";

function ShiftList() {

    const navigate = useNavigate();
    const loading = useRef(true);
    const location = useLocation();
    const eventHandler = useContext(EventContext);

    const [shiftState, setShiftState] = useState({
        busy: true,
        error: false as any,
        shifts: [] as any,
        page: 0,
        pageKey: '',
        totalPages: 0,
        ...location.state
    });

    const formatShifts = (shifts: any) => {
        let output: any = {};
        shifts.forEach((shift: any) => {
            const date = moment(shift.starts_at, 'YYYY-MM-DD HH:mm'),
                formattedDate = date.format('Do MMMM YYYY'),
                week = date.format('W YYYY');
            if (!(week in output)) {
                output[week] = {};
            }
            if (!(formattedDate in output[week])) {
                output[week][formattedDate] = [];
            }
            output[week][formattedDate].push(shift);
        });
        return output;
    }

    const showShift = (shift: any) => {
        return () => {
            navigate('/shifts/view/' + shift.id);
        };
    }

    const requestShifts = (): Promise<void> => {
        Sentry.addBreadcrumb({
            category: 'shifts',
            message: 'Request',
            level: 'info',
        });

        setShiftState({ ...shiftState, busy: true });
        eventHandler.trigger('shifts:request');
        return Promise.resolve();
    };

    useEffect(() => {
        const receiveShifts = (shifts: any) => {
            Sentry.addBreadcrumb({
                category: 'shifts',
                message: 'Receive',
                level: 'info',
            });
            shifts = formatShifts(shifts);
            setShiftState({ ...shiftState, busy: false, shifts, page: 0, totalPages: Object.keys(shifts).length, pageKey: (Object.keys(shifts).length ? Object.keys(shifts)[0] : false) });
        }
        const actionsSync = () => {
            setShiftState({ ...shiftState, busy: true });
        }
        if (loading.current) {
            eventHandler.trigger('actions:sync');
            loading.current = false;
        }
        eventHandler.on('shifts:receive', receiveShifts);
        eventHandler.on('actions:sync', actionsSync);
        return () => {
            eventHandler.off('shifts:receive', receiveShifts);
            eventHandler.off('actions:sync', actionsSync);
        };
    }, [eventHandler, shiftState]);

    const moveNext = () => {
        setShiftState((state: any) => ({ ...state, page: state.page + 1, pageKey: Object.keys(state.shifts)[state.page + 1] }));
    }

    const movePrevious = () => {
        setShiftState((state: any) => ({ ...state, page: state.page - 1, pageKey: Object.keys(state.shifts)[state.page - 1] }));
    }

    const [startPoint, setStartPoint] = useState(0);
    const [pullChange, setPullChange] = useState(0);

    const pullStart = (e: any) => {
        const { screenY } = e.targetTouches[0];
        setStartPoint(screenY);
    }

    const pull = (e: any) => {
        const touch = e.targetTouches[0];
        const { screenY } = touch;
        setPullChange(startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    }

    const endPull = (e: any) => {
        setStartPoint(0);
        setPullChange(0);
        if (pullChange > 220) requestShifts();
    }

    useEffect(() => {
        window.addEventListener('touchstart', pullStart);
        window.addEventListener('touchmove', pull);
        window.addEventListener('touchend', endPull);
        return () => {
            window.removeEventListener('touchstart', pullStart);
            window.removeEventListener('touchmove', pull);
            window.removeEventListener('touchend', endPull);
        };
    });

    return (
        <div className={`Shifts ${(shiftState.busy ? 'is--busy' : '')}`}>
            {shiftState.error !== false && (
                <Modal onDismiss={() => setShiftState({ ...shiftState, error: false })} title={shiftState.error.title}>{shiftState.error.message}</Modal>
            )}
            <h1>Upcoming Shifts</h1>
            <div className="ShiftsList" style={{ marginTop: pullChange / 3.118 || "" }}>
                {shiftState.busy && (
                    <div className="ShiftsListBusy">
                        <Busy />
                    </div>
                )}
                {Object.keys(shiftState.shifts).length === 0 && (
                    <div className="ShiftsList--Empty">You have not been assigned any shifts.</div>
                )}
                {Object.keys(shiftState.shifts).length > 0 && Object.keys(shiftState.shifts[shiftState.pageKey]).map((date: any) => {
                    return (
                        <React.Fragment key={date}>
                            <h2>{date}</h2>
                            {shiftState.shifts[shiftState.pageKey][date].map((shift: any) => {
                                const time = moment(shift.starts_at, 'YYYY-MM-DD HH:mm').format('h:mma');
                                return (
                                    <button key={shift.id} onClick={showShift(shift)} className="ShiftsListItem">
                                        <div className="ShiftsListItemContent">
                                            <strong>{shift.client.name}</strong>
                                            <span>{time}</span> {shift.locations.length > 1 && (<span>Multiple Locations</span>)} {shift.locations.length === 1 && (<><span>{shift.locations[0].site.name}</span></>)}
                                        </div>
                                        <span><IconArrow width={15.91} height={24.6} /></span>
                                    </button>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
                {Object.keys(shiftState.shifts).length > 1 && (
                    <div className="ShiftsPagination">
                        {shiftState.page > 0 && (
                            <button type="button" onClick={movePrevious} className="Previous">
                                <IconArrow width={15.91} height={24.6} />
                                Previous
                            </button>
                        )}
                        {shiftState.page < shiftState.totalPages - 1 && (
                            <button type="button" onClick={moveNext} className="Next">
                                Next
                                <IconArrow width={15.91} height={24.6} />
                            </button>
                        )}
                    </div>
                )}
            </div>

        </div>
    );
}

export default ShiftList;
