import { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventContext } from '../../Contexts/Events';
import Busy from '../../Components/Busy';
import Modal from '../../Components/Modal';
import { ReactComponent as IconArrow } from '../../assets/images/arrow.svg';
import moment from 'moment';


function NotificationList() {
    const navigate = useNavigate();
    const loading = useRef(true);
    const eventHandler = useContext(EventContext);
    const location = useLocation();

    const [notificationState, setNotificationState] = useState({
        busy: true,
        notifications: [],
        error: false as any,
        ...location.state
    });

    useEffect(() => {
        if (loading.current) {
            loading.current = false;
            eventHandler.trigger('notifications:request');
        }
    }, [navigate, eventHandler]);

    useEffect(() => {
        const receiveNotifications = (notifications: any) => {
            setNotificationState((notificationState: any) => ({ ...notificationState, busy: false, notifications }));
        }
        eventHandler.on('notifications:receive', receiveNotifications);
        return () => {
            eventHandler.off('notifications:receive', receiveNotifications);
        }
    }, [eventHandler]);

    const requestNotifications = (): Promise<void> => {
        setNotificationState({...notificationState, busy: true});
        eventHandler.trigger('notifications:request');
        return Promise.resolve();
    };

    const showNotification = (notification: any) => {
        navigate('/notifications/view/' + notification.id);
    }

    const [startPoint, setStartPoint] = useState(0);
    const [pullChange, setPullChange] = useState(0);

    const pullStart = (e: any) => {
        const { screenY } = e.targetTouches[0];
        setStartPoint(screenY);
    }

    const pull = (e: any) => {
        const touch = e.targetTouches[0];
        const { screenY } = touch;
        setPullChange(startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    }

    const endPull = (e: any) => {
        setStartPoint(0);
        setPullChange(0);
        if (pullChange > 220) requestNotifications();
    }

    useEffect(() => {
        window.addEventListener('touchstart', pullStart);
        window.addEventListener('touchmove', pull);
        window.addEventListener('touchend', endPull);
        return () => {
            window.removeEventListener('touchstart', pullStart);
            window.removeEventListener('touchmove', pull);
            window.removeEventListener('touchend', endPull);
        };
    });

    return (
        <div className={`Notifications`}>
            {notificationState.error !== false && (
                <Modal onDismiss={() => setNotificationState({ ...notificationState, error: false })} title={notificationState.error.title}>{notificationState.error.message}</Modal>
            )}
            <h1>Notifications</h1>
            <div className="NotificationsList" style={{ marginTop: pullChange / 3.118 || "" }}>
                {notificationState.busy && (
                    <div className="NotificationsListBusy">
                        <Busy />
                    </div>
                )}
                {notificationState.notifications.length === 0 && (
                    <div className="NotificationsList--Empty">You do not have any notifications.</div>
                )}
                {notificationState.notifications.length > 0 && (
                    <>
                        {notificationState.notifications.map((notification: any) => {
                            const time = moment(notification.created_at, 'YYYY-MM-DD HH:mm').fromNow();
                            return (
                                <button key={notification.id} onClick={() => showNotification(notification)} className="NotificationsListItem">
                                    <div className="NotificationsListItemContent">
                                        <strong>{notification.data.title}</strong>
                                        <span>{time.substring(0,1).toUpperCase() + time.substring(1)}</span>
                                    </div>
                                    <span><IconArrow width={15.91} height={24.6} /></span>
                                </button>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
}

export default NotificationList;
