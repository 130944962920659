import { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../Components/Busy';
import { EventContext } from '../Contexts/Events';
import Modal from '../Components/Modal';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../Contexts/Data';

function Profile() {
    const eventHandler = useContext(EventContext);
    const dataLayer = useContext(DataContext);
    const loading = useRef(true);
    const navigate = useNavigate();

    const [profileState, setProfileState] = useState({
        busy: true,
        online: navigator.onLine,
        error: false as any,
        errors: {} as any,
        loading: false,
        profile: false as any
    });

    useEffect(() => {
        const receiveProfile = (profile: any) => {
            setProfileState({
                ...profileState, 
                busy: false,
                loading: false,
                profile: profile
            });
        }
        const networkChange = (online: any) => {
            setProfileState({...profileState, online});
        }
        if (loading.current) {
            eventHandler.trigger('profile:request');
            loading.current = false;
        }

        eventHandler.on('network:change', networkChange);
        eventHandler.on('profile:receive', receiveProfile);
        return () => {
            eventHandler.off('network:change', networkChange);
            eventHandler.off('profile:receive', receiveProfile);
        }
    }, [eventHandler, profileState, navigate]);

    const logout = () => {
        setProfileState({...profileState, busy: true});
        eventHandler.trigger('logout');
        navigate('/login');
    }

    const submit = async (e: any) => {
        e.preventDefault();

        const fd = new FormData(e.target);

        let errors: any = {};
        if (!fd.get('first_name')) {
            errors.first_name = 'First Name is required';
        }
        if (!fd.get('last_name')) {
            errors.last_name = 'Last Name is required';
        }
        if (!fd.get('email')) {
            errors.email = 'Email Address is required';
        }
        if (!fd.get('mobile')) {
            errors.mobile = 'Mobile is required';
        }
        if (fd.get('password') !== null && fd.get('password_confirmation') !== null && fd.get('password') !== fd.get('password_confirmation')){
            errors.password = 'Passwords must match';
            errors.password_confirmation = 'Passwords must match';
        }

        if (Object.keys(errors).length === 0) {
            try {
                setProfileState({...profileState, errors: {}, loading: true});
                await dataLayer.getApi().post('/profile', fd);
                e.target.reset();
                eventHandler.trigger('profile:request');
            } catch (e) {
                console.error(e);
            }
        } else {
            setProfileState({...profileState, errors});
        }
    }

    return (
        <div className={`Profile ${profileState.busy ? 'is--busy' : ''}`}>

            {profileState.error !== false && (
                <Modal onDismiss={() => setProfileState({ ...profileState, error: false })} title={profileState.error.title}>{profileState.error.message}</Modal>
            )}
            {profileState.busy && (
                <Busy />
            )}
            {!profileState.busy && (
                <>
                    <h1>Profile</h1>
                    <form method="post" onSubmit={submit}>
                        <label>First Name</label>
                        <input type="text" className={('first_name' in profileState.errors ? 'has-error' : '')} disabled={!profileState.online || profileState.loading} autoComplete="first-name" defaultValue={profileState.profile.first_name} name="first_name" placeholder="First Name" />
                        <label>Last Name</label>
                        <input type="text" className={('last_name' in profileState.errors ? 'has-error' : '')} disabled={!profileState.online || profileState.loading} autoComplete="last-name" defaultValue={profileState.profile.last_name} name="last_name" placeholder="Last Name" />
                        <label>Email Address</label>
                        <input type="email" className={('email' in profileState.errors ? 'has-error' : '')} disabled={!profileState.online || profileState.loading} autoComplete="email" defaultValue={profileState.profile.email} name="email" placeholder="Email Address" />
                        <label>Mobile</label>
                        <input type="tel" className={('mobile' in profileState.errors ? 'has-error' : '')} disabled={!profileState.online || profileState.loading} autoComplete="mobile" defaultValue={profileState.profile.mobile} name="mobile" placeholder="Mobile Number" />
                        <br />
                        <label>New Password</label>
                        <input type="password" className={('password' in profileState.errors ? 'has-error' : '')} disabled={!profileState.online || profileState.loading} autoComplete="new-password" name="password" placeholder="New Password" />
                        <label>Confirm New Password</label>
                        <input type="password" className={('password_confirmation' in profileState.errors ? 'has-error' : '')} disabled={!profileState.online || profileState.loading} autoComplete="confirm-new-password" name="password_confirmation" placeholder="Confirm New Password" />
                        <small>Only enter a password when wishing to change it.</small>
                        <br />
                        <br />
                        <label>Communication Preferences</label>
                        <ul>
                            <li>
                                <input type="checkbox" disabled={!profileState.online || profileState.loading} defaultChecked={profileState.profile.communication_preferences.indexOf('mail') > -1} id="communication-preferences-mail" name="communication_preferences[0]" value="mail" />
                                <label htmlFor="communication-preferences-mail">Email</label>
                            </li>
                            <li>
                                <input type="checkbox" disabled={!profileState.online || profileState.loading} defaultChecked={profileState.profile.communication_preferences.indexOf('database') > -1} id="communication-preferences-database" name="communication_preferences[1]" value="database" />
                                <label htmlFor="communication-preferences-database">App Notification</label>
                            </li>
                            <li>
                                <input type="checkbox" disabled={!profileState.online || profileState.loading} defaultChecked={profileState.profile.communication_preferences.indexOf('push') > -1} id="communication-preferences-push" name="communication_preferences[2]" value="push" />
                                <label htmlFor="communication-preferences-push">Push Notification</label>
                            </li>
                            <li>
                                <input type="checkbox" disabled={!profileState.online || profileState.loading} defaultChecked={profileState.profile.communication_preferences.indexOf('sms') > -1} id="communication-preferences-sms" name="communication_preferences[3]" value="sms" />
                                <label htmlFor="communication-preferences-sms">SMS</label>
                            </li>
                        </ul>
                        <button disabled={!profileState.online || profileState.loading}>
                            {profileState.loading ? <Busy /> : 'Update Profile'}
                        </button>
                        <button disabled={!profileState.online || profileState.loading} type="button" onClick={logout}>
                            {profileState.loading ? <Busy /> : 'Logout'}
                        </button>
                    </form>
                </>
            )}
        </div>
    )
}

export default Profile;