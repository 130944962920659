import { createContext } from "react";

export default class Authentication {
    token_key: string = 'pe-token';
    expiration_key: string = 'pe-token-expiration';
    onboard_key: string = 'pe-onboard';
    user_id_key: string = 'pe-user_id';
    permissions_key: string = 'pe-permissions';
    install_dismissed_key: string = 'pe-install-dismissed';
    update_available_key: string = 'pe-update-available';

    token() {
        return localStorage.getItem(this.token_key);
    }

    expires() {
        const expires: any = localStorage.getItem(this.expiration_key);
        if (expires) {
            return expires * 1000;
        }
        return Date.now();
    }

    check() {
        return this.token() !== null && this.expires() > Date.now();
    }

    logout() {
        console.log('Logout fired!');
        localStorage.removeItem(this.token_key);
        localStorage.removeItem(this.expiration_key);
        localStorage.removeItem(this.onboard_key);
        localStorage.removeItem(this.user_id_key);
        localStorage.removeItem(this.permissions_key);
        localStorage.removeItem(this.install_dismissed_key);
        localStorage.removeItem(this.update_available_key);
    }

    login(token: string, expires: number) {
        localStorage.setItem(this.token_key, token);
        localStorage.setItem(this.expiration_key, expires.toString());
    }

    onboarded(compare: any = undefined) {
        const onboard = localStorage.getItem(this.onboard_key);
        return onboard && (compare === undefined || onboard >= compare);
    }

    onboardingVersion() {
        const onboard = localStorage.getItem(this.onboard_key);
        return onboard;
    }

    resetOnboarding() {
        localStorage.removeItem(this.onboard_key);
    }

    setOnboarded(version: any) {
        localStorage.setItem(this.onboard_key, version);
    }

    updateAvailable() {
        const version = localStorage.getItem(this.update_available_key);
        return !!version;
    }

    updateVersion() {
        const version = localStorage.getItem(this.update_available_key);
        return version;
    }

    setUpdateAvailable(version: string) {
        localStorage.setItem(this.update_available_key, version);
    }

    clearUpdateAvailable() {
        localStorage.removeItem(this.update_available_key);
    }

    userId() {
        return Number.parseInt(localStorage.getItem(this.user_id_key) as string);
    }

    setUserId(user_id: any) {
        localStorage.setItem(this.user_id_key, user_id);
    }

    resetPermissions() {
        localStorage.removeItem(this.permissions_key);
    }

    hasPermissions() {
        let permissions: any = localStorage.getItem(this.permissions_key);
        if (permissions) {
            permissions = JSON.parse(permissions);
        }
        permissions = {            
            location: undefined,
            notifications: undefined,
            ...permissions, 
        };
        return permissions.location !== undefined && permissions.notifications !== undefined;
    }

    async checkPermissions() {
        return new Promise((resolve: any, reject: any) => {
            navigator.permissions.query({ name: 'geolocation' }).then((result: any) => {
                if (result && result.state === 'prompt' && this.hasPermissions()) {
                    navigator.geolocation.getCurrentPosition(() => {
                        resolve(true);
                    }, (e: any) => {
                        resolve(false, e);
                    });
                } else {
                    return resolve(result && result.state === 'granted');
                }
            }).catch((e) => {
                resolve(false, e);
            });
        });
    }

    setPermissions(permissions: any) {
        localStorage.setItem(this.permissions_key, JSON.stringify(permissions));
    }
}


export const AuthenticationContext = createContext(null as any);