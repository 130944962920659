import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../Components/Busy';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../Contexts/Authentication';
import { registerHandlers, unRegisterHandlers } from '../Components/ErrorHandler';
import Modal from '../Components/Modal';
import { DataContext } from '../Contexts/Data';

function Onboarding() {

    const location = useLocation();
    const navigation = useRef(useNavigate());
    const authentication = useContext(AuthenticationContext);
    const loading = useRef(true);
    const dataLayer = useContext(DataContext);
    const apiRequest = dataLayer.getApi();

    const [onboardingState, setOnboardingState] = useState({
        busy: true,
        error: false,
        onboarding: false as any,
        ...location.state
    });

    const showError = useCallback((e: any) => {
        setOnboardingState({ ...onboardingState, busy: false, error: true });
    }, [onboardingState]);

    useEffect(() => {
      const handle = registerHandlers(showError);
      return () => {
        unRegisterHandlers(handle);
      }
    }, [showError]);

    useEffect(() => {
        if (loading.current) {
            const fetchOnboardingData = async () => {
                const body = await apiRequest.get('/onboarding');
                setOnboardingState({...onboardingState, busy: false, onboarding: body.data });
            }
            try {
                fetchOnboardingData();
            } catch (e) {
                showError(e);
            }
            loading.current = false;
        }
    }, [setOnboardingState, onboardingState, apiRequest, loading, showError]);

    useEffect(() => {
        if (!onboardingState.busy) {
            if (authentication.onboarded()) {
                navigation.current('/');
            }
        }
    }, [onboardingState, authentication, navigation]);

    const submit = async () => {
        try {
            setOnboardingState({...onboardingState, busy: true });
            await apiRequest.post('/onboarding', {
                onboarding_id: onboardingState.onboarding.id
            });
            authentication.setOnboarded(onboardingState.onboarding.id);
            navigation.current('/');
        } catch (e) {
            showError(e);
        }
    }

    return (
        <div className="Onboarding">
            {onboardingState.error && (
                <Modal onDismiss={() => setOnboardingState({ ...onboardingState, error: false })} title="Onboarding Failed">There was a problem processing your request.<br /><br />Please try again.</Modal>
            )}
            <div className="OnboardingContent">
                {onboardingState.onboarding !== false && (
                    <div>
                        {onboardingState.onboarding.content.split('\n\n').map((item: any, key: any) => {
                            return <p key={key}>{item}</p>
                        })}
                    </div>
                )}
            </div>
            <button type="button" onClick={submit} disabled={onboardingState.busy}>
                {onboardingState.busy ? <Busy /> : 'Accept'}
            </button>
        </div>
    );
}

export default Onboarding;
