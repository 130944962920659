import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../Contexts/Authentication';
import Logo from '../../assets/images/logo.png';
import { ReactComponent as IconShifts } from '../../assets/images/shifts.svg';
import { ReactComponent as IconHolidays } from '../../assets/images/holidays.svg';
import { ReactComponent as IconResources } from '../../assets/images/resources.svg';
import { ReactComponent as IconProfile } from '../../assets/images/proflie.svg';
import { ReactComponent as IconNotification } from '../../assets/images/notification-01.svg';
import { ReactComponent as IconNotificationActive } from '../../assets/images/notification-02.svg';
import { EventContext } from '../../Contexts/Events';
import { DataContext } from '../../Contexts/Data';


function Private() {
  const authentication = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const loading = useRef(true);
  const eventHandler = useContext(EventContext);

  const dataLayer = useContext(DataContext);
  dataLayer.getApi().registerNavigate(navigate);

  const [online, setOnline] = useState(navigator.onLine);
  const [unreadNotifications, setUnreadNotifications] = useState(false);

  useEffect(() => {
    if (authentication.updateAvailable()) {
      navigate('/update');
      return;
    }
    if (!authentication.check()) {
      navigate('/login');
      return;
    }
    if (!authentication.onboarded()) {
      navigate('/onboarding');
      return;
    }
    if (!authentication.hasPermissions()) {
      navigate('/setup');
      return;
    }
    authentication.checkPermissions().then((result: any) => {
      if (!result) {
        authentication.resetPermissions();
        navigate('/setup');
      }
    });
    if (loading.current) {
      loading.current = false;
      eventHandler.trigger('notifications:push-check');
      eventHandler.trigger('notifications:request');

      if (navigator.onLine) {
        dataLayer.getApi().get('/onboarding').then((onboarding: any) => {
          if (onboarding.data.id > authentication.onboardingVersion()) {
            authentication.resetOnboarding();
            navigate('/onboarding', {
              state: {
                onboarding: onboarding.data
              }
            });
          }
        });
      }
    }
  }, [authentication, navigate, eventHandler, dataLayer]);

  const onlineUpdated = useCallback(() => {
    setOnline(navigator.onLine);
    eventHandler.trigger('notifications:request');
  }, [eventHandler]);

  useEffect(() => {
    const receiveNotifications = (notifications: any) => {
      const has_unread: boolean = notifications.reduce((acc: any, notification: any) => {
        if (!notification.read_at) {
          acc = true;
        }
        return acc;
      }, false);
      setUnreadNotifications(has_unread);
    }

    const handleNetworkChange = () => {
      setOnline(navigator.onLine);
      eventHandler.trigger('notifications:push-check');
    }

    eventHandler.on('network:change', handleNetworkChange);
    eventHandler.on('notifications:receive', receiveNotifications);
    return () => {
      eventHandler.off('network:change', handleNetworkChange);
      eventHandler.off('notifications:receive', receiveNotifications);
    }
  }, [onlineUpdated, eventHandler]);

  const [debugState, setDebugState] = useState(0);

  const detectDebug = (e: any) => {
    if (debugState === 0) {
      setTimeout(() => {
        setDebugState(0);
      }, 5000);
    }
    setDebugState((value: any) => value + 1);
    if (debugState >= 7) {
      e.preventDefault();
      setDebugState(0);
      navigate('/debug');
    }
  }

  return (
    <div className="PrivateLayout" onClick={detectDebug}>
      <header>
        <Link to="/">
          <img src={Logo} className="PrivateLogo" alt="Edwards Commercial Cleaning" width="150" height="102" />
        </Link>
        {authentication.onboarded() && authentication.hasPermissions() && !authentication.updateAvailable() && (
          <button onClick={() => navigate('/notifications')} className="Notifications">
            {!unreadNotifications && (
              <IconNotification width={24} height={24} />
            )}
            {unreadNotifications && (
              <IconNotificationActive width={24} height={24} />
            )}
          </button>
        )}
      </header>
      <main>
        {!online && (
          <div className="Offline">You are currently offline.<br />Data will be synchronised once reconnected.</div>
        )}
        <Outlet />
      </main>
      {authentication.onboarded() && authentication.hasPermissions() && !authentication.updateAvailable() && (
        <footer>
          <button onClick={() => navigate('/')} className={location.pathname === '/' || location.pathname.indexOf('/shifts') > -1 ? 'active' : ''}>
            <IconShifts width={32} height={32} />
          </button>
          <button onClick={() => navigate('/holidays')} className={location.pathname.indexOf('/holidays') > -1 ? 'active' : ''}>
            <IconHolidays width={32} height={32} />
          </button>
          <button onClick={() => navigate('/resources')} className={location.pathname.indexOf('/resources') > -1 ? 'active' : ''}>
            <IconResources width={32} height={32} />
          </button>
          <button onClick={() => navigate('/profile')} className={location.pathname.indexOf('/profile') > -1 ? 'active' : ''}>
            <IconProfile width={32} height={32} />
          </button>
        </footer>
      )}
    </div>
  );
}

export default Private;
