import { useContext, useState } from 'react';
import './index.scss';
import { DataContext } from '../Contexts/Data';
import { urlBase64ToUint8Array } from '../Components/ApiRequest';
import Busy from '../Components/Busy';
import Modal from '../Components/Modal';

function Debug() {
    const dataLayer = useContext(DataContext);
    const apiRequest = dataLayer.getApi();

    const build_version = process.env.REACT_APP_VERSION || '0.0.0';
    const build_date = process.env.REACT_APP_BUILD_DATE || 'N/A';

    const [locationTestState, setLocationTestState] = useState({
        location: false as any,
        error: false as any,
        busy: false
    });

    const sendTestPushNotification = async () => {
        await apiRequest.post('/push-test', {});
    }

    const reloadApp = () => {
        window.location.reload();
    }

    const checkLocation = () => {
        setLocationTestState((state: any) => ({...state, busy: true}));
        navigator.geolocation.getCurrentPosition((pos: any) => {
            if (pos.coords) {
                setLocationTestState((state: any) => ({...state, location: pos, busy: false}));
            } else {
                setLocationTestState((state: any) => ({...state, error: 'Invalid location.', busy: false}));
            }
        }, (e: any) => {
            setLocationTestState((state: any) => ({...state, error: e.message, busy: false}));
        }, {
            maximumAge: 5000
        });
    }

    const resetPushNotifications = async () => {
        if ('Notification' in window && Notification.permission === 'granted' && navigator.onLine) {
            // Ensure the server has the latest push subscription.
            navigator.serviceWorker.getRegistration().then((registration: any) => {
                registration.pushManager.getSubscription().then((subscription: any) => {
                    if (subscription) {
                        dataLayer.getApi().post('/register-push', subscription);
                    } else {
                        throw new Error('Invalid subscription');
                    }
                }).catch(() => {
                    registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_KEY || 'BKBAegtea0h174qt5bN8EtksU03EnuoW7NdShxcmjF9PyB0LYaIPA1R4DEIBRDl5fyi1z07U2ZMFi0PhRUSmEKY')
                    }).then((subscription: any) => {
                        if (subscription) {
                            dataLayer.getApi().post('/register-push', subscription);
                        }
                    });
                });
            });
        }
    }

    return (
        <div className="Debug">
            <p><strong>Build Version</strong><br />{build_version}</p>
            <p><strong>Build Date</strong><br />{build_date}</p>
            {'Notification' in window && (
                <p><button type="button" onClick={sendTestPushNotification}>Test Push Notification</button><button className="Alt" type="button" onClick={resetPushNotifications}>Reset Push Notifications</button></p>
            )}
            <button className="Alt2" type="button" disabled={locationTestState.busy} onClick={checkLocation}>
                {locationTestState.busy ? <Busy /> : 'Test Location'}
            </button>
            <button type="button" onClick={reloadApp}>Restart App</button>
            {(locationTestState.error || locationTestState.location) && (
                <Modal title="Location Test" onDismiss={() => setLocationTestState({...locationTestState, location: undefined, error: undefined})}>
                    <div>
                        {locationTestState.error && (
                            <>
                                <p><strong>Fail, we could not get your location:</strong></p>
                                <p>{locationTestState.error}</p>
                            </>
                        )}
                        {locationTestState.location && (
                            <>
                                <p><strong>Success, your location is:</strong></p>
                                <p>{locationTestState.location.coords.latitude}, {locationTestState.location.coords.longitude}</p>
                            </>
                        )}
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default Debug;
