import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Authentication, { AuthenticationContext } from './Contexts/Authentication';
import EventHandler, { EventContext } from './Contexts/Events';
import Data, { DataContext } from './Contexts/Data';
import ApiRequest from './Components/ApiRequest';
import * as Sentry from "@sentry/react";

const authentication = new Authentication();
const apiRequest = new ApiRequest(authentication);
const eventHandler = new EventHandler();
const dataLayer = new Data(eventHandler, apiRequest);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Sentry.init({
  dsn: "https://9765fc46ffebfeae9dc0be07b92bb4ac@o4506755687710720.ingest.sentry.io/4506755689283584",
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/pe\.edwardscleaningltd\.com\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const build_version = process.env.REACT_APP_VERSION || '0.0.0';

Sentry.setContext('app', {
  build: build_version
});

root.render(
  <React.StrictMode>
    <AuthenticationContext.Provider value={authentication}>
      <EventContext.Provider value={eventHandler}>
        <DataContext.Provider value={dataLayer}>
          <App />
        </DataContext.Provider>
      </EventContext.Provider>
    </AuthenticationContext.Provider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();